'use client';
import styles from './NotFound.module.scss';
import { useTranslations } from 'next-intl';
import { SessionProvider } from 'next-auth/react';
import { RedirectButton } from '@/components/RedirectButton';

export default function NotFound() {
  const t = useTranslations();

  return (
    <SessionProvider>
      <div className={styles.wrapper}>
        <div className={styles.sign}>404</div>
        <div className={styles.content}>
          <p className={styles.title}>{t('notFound.title')}</p>
          <p className={styles.description}>{t('notFound.description')}</p>
        </div>
        <div className={styles.buttons}>
          <RedirectButton />
        </div>
      </div>
    </SessionProvider>
  );
}
