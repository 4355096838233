import { Button } from '@/atoms/Button';
import { useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';

export function RedirectButton() {
  const t = useTranslations();
  const { push } = useRouter();
  const { data: session } = useSession();
  const path = session ? '/account' : '/';

  return (
    <Button
      text={t('notFound.button')}
      onClick={() => push(path)}
      variant="dark"
    />
  );
}
